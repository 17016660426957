export const minImageDimLandscape = {
    minCropWidth: 1800,
    minCropHeight: 1200,
};

export const minImageDimPortrait = {
    minCropWidth: 1200,
    minCropHeight: 1800,
};

export const actionsList = {
    refresh: 'refresh',
    share: 'share',
    download: 'download',
    delete: 'delete',
};

export const tempFilter = [
    {
        id: 1,
        name: 'Small',
    },
    {
        id: 2,
        name: 'Medium',
    },
    {
        id: 3,
        name: 'Large',
    },
];

export const ranges = {
    portrait: {
        minRange: 0.64,
        maxRange: 0.69,
    },
    landscape: {
        minRange: 1.48,
        maxRange: 1.53,
    },
};

export const warnings = {
    wrongAspectRatio: {
        portrait:
            'This photo does not meet the required 2x3 aspect ratio. This may cause the photo to appear distorted when displayed on the web. To fix this, crop the photo using the crop tool.',
        landscape:
            'This photo does not meet the required 3x2 aspect ratio. This may cause the photo to appear distorted when displayed on the web. To fix this, crop the photo using the crop tool.',
    },
    smallImage:
        'This photo does not meet image size requirements and may result in the listing being removed from the web. To fix this, please replace this with a photo which is at least',
};

export const imageSizes = {
    smallLandscape: '1800x1200',
    mediumLandscape: '3600x2400',
    smallPortrait: '1200x1800',
    mediumPortrait: '2400x3600',
};

export const selectionSizes = {
    small: 'small',
    medium: 'medium',
    streetEasy: 'streetEasy',
    santaFe: 'santaFe',
};

export const imageEditMenuForms = {
    imageCrop: 'ImageCrop',
    imageCaption: 'ImageCaption',
    imageTags: 'ImageTags',
    imageDownload: 'ImageDownload',
};

export const aspectRatio = {
    landscape: 3 / 2,
    portrait: 2 / 3,
};
